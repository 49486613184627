import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  ImageList,
  ImageListItem,
  TextField
} from '@material-ui/core'
import isEmail from 'validator/es/lib/isEmail'
import InImage001 from '../Assets/influencer-grid-001.png'
import InImage002 from '../Assets/influencer-grid-002.png'
import InImage003 from '../Assets/influencer-grid-003.png'
import InImage004 from '../Assets/influencer-grid-004.png'
import InImage005 from '../Assets/influencer-grid-005.png'
import InImage006 from '../Assets/influencer-grid-006.png'
import InImage007 from '../Assets/influencer-grid-007.png'
import InImage008 from '../Assets/influencer-grid-008.png'
import InImage009 from '../Assets/influencer-grid-009.png'
import InImage010 from '../Assets/influencer-grid-010.png'
import InImage011 from '../Assets/influencer-grid-011.png'
import InImage012 from '../Assets/influencer-grid-012.png'
import InImage013 from '../Assets/influencer-grid-013.png'
import InImage014 from '../Assets/influencer-grid-014.png'
import InImage015 from '../Assets/influencer-grid-015.png'
import InImage016 from '../Assets/influencer-grid-016.png'
import InImage017 from '../Assets/influencer-grid-017.png'
import InImage018 from '../Assets/influencer-grid-018.png'
import InImage019 from '../Assets/influencer-grid-019.png'
import InImage020 from '../Assets/influencer-grid-020.png'
import InImage021 from '../Assets/influencer-grid-021.png'
import InImage022 from '../Assets/influencer-grid-022.png'
import InImage023 from '../Assets/influencer-grid-023.png'
import InImage024 from '../Assets/influencer-grid-024.png'
import InImage025 from '../Assets/influencer-grid-025.png'
import './Login.scss'

const imageGridData = [
  {
    img: InImage001,
    title: 'girl influencer giving the peace sign'
  },
  {
    img: InImage002,
    title: 'girl influencer leaning against bright colored wall'
  },
  {
    img: InImage003,
    title: 'girl influencer giving the peace sign'
  },
  {
    img: InImage004,
    title: 'girl influencer in makeup store'
  },
  {
    img: InImage005,
    title: 'guy influencer with hat on'
  },
  {
    img: InImage006,
    title: 'girl influencer takes a selfie'
  },
  {
    img: InImage007,
    title: 'guy influencer headshot'
  },
  {
    img: InImage008,
    title: 'girl influencer in yellow dress walks down the street'
  },
  {
    img: InImage009,
    title: 'woman influencer in front of mirror'
  },
  {
    img: InImage010,
    title: 'guy influencer walks along a wall'
  },
  {
    img: InImage011,
    title: 'guy influencer sits in a lobby'
  },
  {
    img: InImage012,
    title: 'guy and girl influencers swim in waterfall'
  },
  {
    img: InImage013,
    title: 'guy influencer gives peace sign on stool'
  },
  {
    img: InImage014,
    title: 'guy influencer leans against car'
  },
  {
    img: InImage015,
    title: 'guy influencer plays computer video game'
  },
  {
    img: InImage016,
    title: 'girl influencer headshot'
  },
  {
    img: InImage017,
    title: 'guy influencer stands next to van in desert'
  },
  {
    img: InImage018,
    title: 'guy influencer runs in city'
  },
  {
    img: InImage019,
    title: 'girl influencer holds a kitten'
  },
  {
    img: InImage020,
    title: 'guy influencer does backflip'
  },
  {
    img: InImage021,
    title: 'guy influencer inside store'
  },
  {
    img: InImage022,
    title: 'girl influencer by foggy lake'
  },
  {
    img: InImage023,
    title: 'girl influencer by the ocean'
  },
  {
    img: InImage024,
    title: 'girl influencer hiking by the lake'
  },
  {
    img: InImage025,
    title: 'girl influencer in the woods'
  }
]

const errorCodes = {
  emailRequired: {
    'username-input': 'E-mail address is required.'
  },
  passwordRequired: {
    'password-input': 'Password is required.'
  },
  InvalidEmailException: {
    'username-input': 'Please enter a valid e-mail address.'
  },
  InvalidPasswordException: {
    'password-input': 'Please choose a stronger password. Passwords must be at least 8 characters long.'
  },
  NotAuthorizedException: {
    'password-input': 'Please check your password.'
  }
}

class _PageLogin extends Component {
  constructor(props) {
    super(props)
    this.imageGridRef = React.createRef()
  }

  componentDidMount = () => {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    imageGridData.sort(() => Math.random() - 0.5)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { current = {} } = this.imageGridRef
    const { offsetWidth: columnWidth } = current
    if (columnWidth) {
      this.setState({ columnWidth })
    }
  }

  state = {
    columns: 4,
    columnWidth: null,
    email: '',
    error: {},
    loading: false,
    password: ''
  }

  login = async () => {
    this.setState({ error: {}, loading: true })
    const { history } = this.props
    const { email, password } = this.state
    if (email.length === 0) {
      this.setState({
        error: errorCodes['usernameRequired'],
        loading: false
      })
      return
    }
    if (!isEmail(email)) {
      this.setState({
        error: errorCodes['InvalidEmailException'],
        loading: false
      })
      return
    }
    if (password.length === 0) {
      this.setState({
        error: errorCodes['passwordRequired'],
        loading: false
      })
      return
    }
    if (password.length < 8) {
      this.setState({
        error: errorCodes['InvalidPasswordException'],
        loading: false
      })
      return
    }
    try {
      await Auth.signIn(email, password)
      history.push('/')
    } catch (error) {
      const { code } = error
      console.log({ code })
      if (code === 'UserNotFoundException') {
        try {
          await Auth.signUp({ username: email, password, attributes: { email } })
          await Auth.signIn(email, password)
          history.push('/')
        } catch (error) {
          const { code } = error
          this.setState({
            error: errorCodes[code] || { unscoped: error },
            loading: false
          })
        }
      } else {
        this.setState({
          error: errorCodes[code] || { unscoped: error },
          loading: false
        })
      }
    }
  }

  submitOnEnter = e => {
    if(e.keyCode === 13){
      e.preventDefault()
      this.login()
    }
  }

  render = () => {
    const {
      columns,
      columnWidth,
      email,
      error,
      loading,
      password
    } = this.state
    const {
      'password-input': passwordInputError,
      unscoped: unscopedError,
      'username-input': usernameInputError
    } = error || {}
    return <div className='PageLogin'>
      <div className='image-grid' ref={this.imageGridRef}>
        {columnWidth && <ImageList rowHeight={Math.floor(columnWidth / columns)} cols={columns}>
          {imageGridData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
              <img src={item.img} alt={item.title} />
            </ImageListItem>
          ))}
        </ImageList>}
      </div>
      <div className='login-form-wrapper'>
        <Container>
          <div className='bold-34 green'>Get All of Me</div>
          <div className='margin-bottom-32'>Where fans and followers become friends.</div>
          <FormControl>
            {unscopedError && <div className='message error margin-bottom-24'>{unscopedError.message}</div>}
            <TextField
              className='margin-bottom-24'
              disabled={loading}
              error={!!usernameInputError}
              helperText={usernameInputError || 'We never share your e-mail address.'}
              label='E-mail address'
              onChange={e => this.setState({ email: e.target.value })}
              onKeyUp={this.submitOnEnter}
              required
              value={email}
              variant='outlined' />
            <TextField
              className='margin-bottom-24'
              disabled={loading}
              error={!!passwordInputError}
              helperText={passwordInputError || 'Please enter your current password. Or, create one if this is your first time here.'}
              label='Password'
              onChange={e => this.setState({ password: e.target.value })}
              onKeyUp={this.submitOnEnter}
              required
              type='password'
              value={password}
              variant='outlined' />
            <Button
              disabled={loading}
              onClick={this.login}>Login</Button>
          </FormControl>
        </Container>
      </div>
    </div>
  }
}

const PageLogin = withRouter(_PageLogin)

export { PageLogin }

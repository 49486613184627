/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cf77662e-d892-4f3d-ba37-7e39a822a096",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VRTQcO9qp",
    "aws_user_pools_web_client_id": "58pies0f9d32rc1d0ptp1pq6tp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "data-production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "getallofme-assets200043-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apid698a263",
            "endpoint": "https://nbuysfgpg2.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;

import { API, Auth } from 'aws-amplify'

export const get = ({ path }) => API.get('apid698a263', `/data${path}`)
export const put = ({ path = '', body }) => API.put('apid698a263', `/data${path}`, { body })

export const putEvent = async ({ context }) => {
  const {
    name,
    data = {}
  } = context
  const { _identityId } = Auth.Credentials
  return put({
    path: '/event',
    body: {
      name,
      data: {
        ...data,
        _identityId
      }
    }
  })
}

import Amplify, { Hub } from 'aws-amplify'
import awsExports from './aws-exports'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.scss'

Amplify.configure(awsExports)

Hub.listen('datastore', async hubData => {
  console.log('DataStore event', { hubData })
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals(console.log)

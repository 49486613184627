import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { DataStore } from 'aws-amplify'
import { withRouter } from 'react-router-dom'

const _withUser = RouteComponent => withRouter(class WithUser extends Component {
  state = {
    error: null,
    loading: true,
    user: {},
    userMeta: []
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  componentDidMount = async () => {
    let cognitoUser
    try {
      cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
    } catch (e) {
      await DataStore.clear()
      const { history } = this.props
      history.replace('/login')
      return
    }
    try {
      /*
      const { sub } = cognitoUser.attributes
      let user, userMeta
      try {
        const users = await DataStore.query(User)
        console.log({ users, sub })
        user = (await DataStore.query(User)).filter(u => u.sub === sub)[0]
      } catch (error) {
        this.setState({ loading: false, error })
        return
      }
      if (!user) {
        console.log('user not found for some reason', { user })
        try {
          user = await DataStore.save(new User({ sub }))
        } catch (error) {
          this.setState({ loading: false, error })
          return
        }
      }
      try {
        userMeta = (await DataStore.query(UserMeta)).filter(u => u.userID === user.id)
      } catch (error) {
        this.setState({ loading: false, error })
      }
      this.setState({ loading: false, user, userMeta }, () => console.log({ state: this.state }))
      */
    } catch (error) {
      this.setState({ loading: false, error })
    }
  }

  render = () => <RouteComponent {...this.state} />
})

const withUser = _withUser

export { withUser }

import React, { Component } from 'react'
import { withUser } from '../HOC/withUser'
import {
  Button,
  FormControl,
  Step,
  StepLabel,
  Stepper,
  TextField
} from '@material-ui/core'

class _RouteOnboarding extends Component {
  state = {
    step: 0,
    steps: [
      'Choose account type',
      'Step 2',
      'Step 3',
      'Step 4'
    ]
  }

  renderStep = () => {
    const { name = '', step, steps } = this.state
    switch (step) {
      case 1:
        return <div className='step step-1'>
          <div>{steps[step]}</div>
          <FormControl>
            <TextField
              className='margin-bottom-24'
              helperText={'Choose a blog name.'}
              label='Blog Name'
              onChange={e => this.setState({ name: e.target.value })}
              onKeyUp={this.submitNameOnEnter}
              required
              value={name}
              variant='outlined' />
          </FormControl>
          <Button onClick={() => this.pickBlogName()} variant="contained">Save</Button>
        </div>
      case 0:
      default:
        return <div className='step step-1'>
          <div>{steps[step]}</div>
          <Button onClick={() => this.pickUserType('influencer')} variant="contained">Influencer Account</Button>
          <div>Pick this account type if you want to invite your fans to become wefluencers.</div>
          <Button onClick={() => this.pickUserType('fan')} variant="contained">Fan</Button>
          <div>Pick this account type if you have been invited to become a wefluencer or want to be.</div>
        </div>
    }
  }

  submitNameOnEnter = e => {
    if(e.keyCode === 13){
      e.preventDefault()
      this.pickBlogName()
    }
  }

  pickUserType = type => {
    if (type === 'influencer') {
      this.setState({
        step: 1,
        steps: [
          'Choose account type',
          'Setup your blog',
          'Add content',
          'Publish your blog & invite fans'
        ]
      })
    } else {
      this.setState({
        step: 1,
        steps: [
          'Choose account type',
          'Enter invite code',
          'Find other invites',
          'Setup your own blog'
        ]
      })
    }
  }

  pickBlogName = () => {

  }

  render = () => {
    const { step, steps } = this.state
    return <div className='RouteOnboarding'>
      <Stepper activeStep={step} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
      </Stepper>
      {this.renderStep()}
    </div>
  }
}

const RouteOnboarding = withUser(_RouteOnboarding)

export { RouteOnboarding }

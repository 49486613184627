import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { PageLogin } from '../Page/Login'

class RouteLogin extends Component {
  state = {
    loading: true
  }

  static propTypes = {
    history: PropTypes.object.isRequired
  }

  componentDidMount = async () => {
    try {
      await Auth.currentAuthenticatedUser({ bypassCache: true })
      const { history } = this.props
      history.replace('/app')
    } catch (e) {
      console.log('RouteLogin auth error.', { e })
    }
  }

  render = () => <div className='RouteLogin'><PageLogin /></div>
}

export { RouteLogin }

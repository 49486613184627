import React from 'react'
import { isBrowser } from 'react-device-detect'
import Button from '../Component/ButtonWithAnalytics'
import { Icon } from 'react-bulma-components'
import ColorizeIcon from '@mui/icons-material/Colorize'
import colors from '../Styles/colors.js'
import './AcceptContent.scss'

const { gray } = colors

const PageAcceptContent = ({
  buttons,
  setHasAccepted
}) => <div className='PageAcceptContent full-height full-width flex-center'>
  <Icon
    className='margin-bottom-12'
    style={{ color: gray, fontSize: 40, height: 40 }}>
    <ColorizeIcon
      fontSize='inherit' />
  </Icon>
  <div className='margin-bottom-32 gray'>Choose...</div>
  {buttons.map(button => {
    const { key, icon, text } = button
    return <Button
    className={`three-quarter-width margin-bottom-12${isBrowser ? ' max-width-300' : ''}`}
    context={{
      name: 'CHOICE_CLICK',
      data: {
        key,
        text
      }
    }}
    key={key}
    onClick={() => setHasAccepted(key)}>{icon} {text}</Button>
  })}
</div>

export default PageAcceptContent

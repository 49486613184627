import React, { Component } from 'react'
import { withUser } from '../HOC/withUser'
import { withRouter } from 'react-router-dom'
import { ComponentAddPost } from '../Component/AddPost'
import { ComponentFullScreenLoader } from '../Component/FullScreenLoader'
import { RouteOnboarding } from '../Route/Onboarding'

class _RoutePortal extends Component {
  render = () => {
    const { error, loading, user = {}, userMeta = [] } = this.props
    const onboardingComplete = !!userMeta.find(meta => meta.key === 'onboardComplete' && meta.value === true)
    return <div className='RoutePortal'>
      {loading
        ? <ComponentFullScreenLoader />
        : error
          ? 'error'
          : !onboardingComplete
            ? <RouteOnboarding />
            : <ComponentAddPost userID={user.id} />
      }
    </div>
  }
}

const RoutePortal = withUser(withRouter(_RoutePortal))

export { RoutePortal }

/*
  user onboarding
    * are you an influencer or a fan?
      - influencer
        * setup a blog
          - subscription required: yes / no
          - allow private feeds: yes / no
            * subscription required: yes / no
        * add 5 posts
          - upload media
          - add caption
          - ppv: yes / no
          - locked: yes / no
            * locked: enter text for what has to happen to unlock it.
        * invite fans
      - fan
        * wefluence is invite only. We couldn't find any invites with the email you provided. If you have an invite code, please enter it now.
          - if they came in on an invite link, use the code in the link
        * If you don't have a code, attach your phone number, other email addresses, and social media profiles to see if you have any invites.
          - Add email
          - Add phone number
          - Facebook
          - Twitter
          - Instagram
  influencer admin
    * add blog
    * add post
    * manage users
  follower feed
*/

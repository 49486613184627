import React, { Fragment, useEffect, useState } from 'react'
import { get, putEvent } from '../Libraries/API'
import { isBrowser } from 'react-device-detect'
import PageLinks from '../Page/Links'
import PageAcceptContent from '../Page/AcceptContent'
import FixedFooter from '../Component/FixedFooter'
import mobileSocks from '../Assets/jay-david-019.jpg'
import broswerSocks from '../Assets/jay-david-020.jpg'
import mobileFlipFlops from '../Assets/jay-david-022.jpg'
import mobileSlides from '../Assets/jay-david-023.jpg'
import mobileBareFeet from '../Assets/jay-david-024.jpg'
import browserBareFeet from '../Assets/jay-david-025.jpg'
import browserShoes from '../Assets/jay-david-026.jpg'
import mobileShoes from '../Assets/jay-david-027.jpg'
import browserSlides from '../Assets/jay-david-028.jpg'
import browserFlipFlops from '../Assets/jay-david-030.jpg'
import as from '../Assets/slides.png'
import ff from '../Assets/flip-flop-emoji.png'

const images = {
  mobile: {
    socks: mobileSocks,
    barefeet: mobileBareFeet,
    shoes: mobileShoes,
    flipFlops: mobileFlipFlops,
    slides: mobileSlides
  },
  browser: {
    socks: broswerSocks,
    barefeet: browserBareFeet,
    shoes: browserShoes,
    flipFlops: browserFlipFlops,
    slides: browserSlides
  }
}

export const RouteLinks = () => {
  const [isOnline, setIsOnline] = useState(false)
  const [postCount, setPostCount] = useState(false)
  useEffect(() => {
    (async () => {
      Object.values(images[isBrowser ? 'browser' : 'mobile']).forEach(image => {
        const img = new Image(0, 0)
        img.src = image
        img.style.position = 'fixed'
        document.body.appendChild(img)
      })
      const [statusResponse, postCountResponse] = await Promise.all([
        get({ path: '/object/jaydavid/status' }),
        get({ path: '/object/jaydavid/postCount' })
      ])
      await putEvent({
        context: {
          name: 'PAGE_VIEW',
          isBrowser
        }
      })
      const { online = false } = statusResponse || {}
      const { NewValue: postCount = 0 } = postCountResponse || {}
      setIsOnline(online)
      setPostCount(postCount)
    })()
  }, [])
  const [showAvailable, setShowAvailable] = useState(false)
  const [showCount, setShowCount] = useState(0)
  const [hasAccepted, setHasAccepted] = useState(false)
  return <div className='RouteLinks full-height'>{
    hasAccepted
    ? <PageLinks
      hasAccepted={hasAccepted}
      images={images}
      isOnline={isOnline}
      postCount={postCount}
      setShowAvailable={setShowAvailable}
      setShowCount={setShowCount}
      showAvailable={showAvailable}
      showCount={showCount} />
    : <PageAcceptContent
      buttons={[
        {
          key: 'barefeet',
          icon: '👣',
          text: 'Barefeet'
        },
        {
          key: 'socks',
          icon: '🧦',
          text: 'Socks'
        },
        {
          key: 'shoes',
          icon: '👟',
          text: 'Shoes'
        },
        {
          key: 'flipFlops',
          icon: <img alt='flip-flop' className='margin-right-6' src={ff} style={{ height: 20 }} />,
          text: 'Flip-Flops'
        },
        {
          key: 'slides',
          icon: <img alt='adidas slides' className='margin-right-6' src={as} style={{ width: 20 }} />,
          text: 'Slides'
        },
        {
          key: 'random',
          icon: '🤷',
          text: 'meh.'
        }
      ]}
      setHasAccepted={setHasAccepted} />
    }
    <FixedFooter setHasAccepted={setHasAccepted} />
  </div>
}

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

class RouteLogout extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  componentDidMount = async () => {
    try {
      await Auth.signOut()
      const { history } = this.props
      history.push('/login')  
    } catch (e) {
      console.log('error logging out', { e })
    }
  }

  render = () => <Fragment />
}

export { RouteLogout }

import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { RouteLinks } from './Route/Links'
import { RouteLogin } from './Route/Login'
import { RouteLogout } from './Route/Logout'
import { RoutePortal } from './Route/Portal'

export const Router = () => <BrowserRouter>
  <Switch>
    <Route
      component={RouteLogin}
      path='/login' />
    <Route
      component={RouteLogout}
      path='/logout' />
    <Route
      path='/app'
      render={props => <RoutePortal {...props} />} />
    <Route
      component={RouteLinks} />
  </Switch>
</BrowserRouter>

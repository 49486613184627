import React from 'react'
import { putEvent } from '../Libraries/API'
import { Button } from 'react-bulma-components'

const ComponentButtonWithAnalytics = ({ context, onClick, ...props }) => {
  const eventOnClick = () => {
    putEvent({ context })
    onClick()
  }
  return <Button
    onClick={eventOnClick}
    {...props} />
}

export default ComponentButtonWithAnalytics

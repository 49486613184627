import React from 'react'
import { putEvent } from '../Libraries/API'
import { Link } from 'react-router-dom'
import './FixedFooter.scss'

const ComponentFixedFooter = ({ setHasAccepted }) => <Link
  className='FixedFooter'
  onClick={() => {
    putEvent({
      context: {
        name: 'HOMEPAGE_CLICK'
      }
    })
    setHasAccepted()
  }}
  to='/'>
  <div className='light-25 padding-horizontal-16 white'>Jay David</div>
</Link>

export default ComponentFixedFooter

import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import Button from '../Component/ButtonWithAnalytics'
import { Instagram, Twitter } from '@material-ui/icons'
import { isBrowser } from 'react-device-detect'
import of from '../Assets/of-logo.png'
import './Links.scss'

const buttons = [
  {
    className: 'twitter-bg',
    href: 'https://twitter.com/the_jaydavid',
    jsx: <Fragment><Twitter className='margin-right-6' />Twitter</Fragment>
  },
  {
    className: 'instagram-bg',
    href: 'https://instagram.com/the_jaydavid',
    jsx: <Fragment><Instagram className='margin-right-6' />Instagram</Fragment>
  }
]

const PageLinks = ({
  hasAccepted,
  images,
  isOnline,
  postCount,
  setShowAvailable,
  setShowCount,
  showAvailable,
  showCount
}) => {
  if (hasAccepted === 'random') hasAccepted = _.sample(Object.keys(images.mobile))
  const style = {
    backgroundImage: `url(${images[isBrowser ? 'browser' : 'mobile'][hasAccepted]}`
  }
  useEffect(() => {
    setTimeout(() => {
      setShowAvailable(isOnline)
      setShowCount(postCount)
    }, 300)
  }, [isOnline, postCount])
  const of_href = 'https://onlyfans.com/jay_david'
  return <div className='PageLinks full-height full-width flex-center'>
    <div className='links-wrapper full-width full-height' style={style}>
      <div className='flex-center full-width full-height'>
        <div className={`online-status margin-bottom-12 is-online${isBrowser ? ' max-width-300' : ''}`}>
          <Button
            className='full-width'
            context={{
              name: 'LINK_CLICK',
              data: {
                hasAccepted,
                href: of_href,
                isBrowser,
                isOnline,
                postCount,
                showAvailable,
                showCount
              }
            }}
            onClick={() => window.location.href = of_href}>
            <img
              alt='onlyfans logo'
              className='of-button-image'
              src={of} />
          </Button>
          <div className='is-online-text regular-13 margin-top-4 white'>
            <div className='margin-right-8'>@jay_david</div>
            <div className={`${showAvailable ? 'green-dot' : 'grey-dot'} margin-right-4`} />
            <div>{showAvailable
              ? <strong className='white'>Available</strong>
              : <span>Active Today</span>
            }</div>
            {showCount > 0 && <div className='margin-left-8'>{showCount} New Post{showCount > 1 && 's'}</div>}
          </div>
        </div>
        {buttons.map(button => {
          const { className, href, jsx } = button
          return <Button
          className={`three-quarter-width margin-bottom-12${className ? ` ${className}` : ''}${isBrowser ? ' max-width-300' : ''}`}
          color='primary'
          context={{
            name: 'LINK_CLICK',
            data: {
              hasAccepted,
              href,
              isBrowser,
              isOnline,
              postCount,
              showAvailable,
              showCount
           }
          }}
          key={href}
          onClick={() => { window.location.href = href }}>{jsx}</Button>
        })}
      </div>
    </div>
  </div>
}

export default PageLinks

const colors = {
  black: '#0B0C10',
  gray: '#1F2833',
  silver: '#C5C6C7',
  teal: '#66FCF1',
  turquoise: '#7bc9e9',
  green: '#45A29E',
  greenTransparent: 'rgba(69, 162, 158, 0.75)',
  blue: '#454aa2',
  yellow: '#a27245',
  red: '#a24547',
  purple: '#7245a2',
  white: '#ffffff',
  instagram: '#5851D8',
  twitter: '#1DA1F2',
  onlyfans: 'rgb(0, 175, 240)',
  onlyfansTransparent: 'rgba(0, 175, 240, 0.7)'
}

export default colors
